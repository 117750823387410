"use strict";

import "modern-css-reset";
import "animate.css";
import "fullpage.js/dist/fullpage.css";
import "../scss/style.scss";
import "../scss/panel-menu.scss";

import "jquery-inview";
import "slider-pro";
import fullpage from "fullpage.js";
import { gsap, Linear, Power1, Bounce } from "gsap";

import mainTitle from "./svg/main-title";
import web from "./svg/web";
import design from "./svg/design";
import system from "./svg/system";
import more from "./svg/more";

jQuery(($) => {
  const node = [
    {
      anchor: "#home",
      title: "RESONATING DESIGN",
    },
    {
      anchor: "#concept",
      title: "CONCEPT",
    },
    {
      anchor: "#service",
      title: "SERVICE",
    },
    {
      anchor: "#about",
      title: "ABOUT",
    },
    {
      anchor: "#staff",
      title: "STAFF",
    },
    {
      anchor: "/contact/",
      title: "CONTACT",
    },
  ];

  const anchors = [];
  $.each(node, (index, value) => {
    anchors.push(value.anchor.replace(/[#\/]/g, ""));
  });

  const services = {
    web: web,
    design: design,
    system: system,
    more: more,
  };
  $.each(services, (index, element) => {
    $(`.icon.${index} .icon__main a`).html(element);
  });

  let isFirst = true;

  const tl = {
    normal: {
      web: new gsap.timeline({
        repeat: -1,
      })
        .set("#svg--web--1", {
          transformOrigin: "center",
        })
        .to(
          "#svg--web--1",

          {
            duration: 12,
            startAt: {
              rotationY: 0,
            },
            rotationY: 360,
            ease: Linear.easeNone,
          },
          "-=12"
        )
        .set("#svg--web--2", {
          transformOrigin: "center",
        })
        .to(
          "#svg--web--2",

          {
            duration: 12,
            startAt: {
              rotationY: -120,
            },
            rotationY: 240,
            ease: Linear.easeNone,
          },
          "-=12"
        )
        .set("#svg--web--3", {
          transformOrigin: "center",
        })
        .to(
          "#svg--web--3",

          {
            duration: 12,
            startAt: {
              rotationY: -240,
            },
            rotationY: 120,
            ease: Linear.easeNone,
          },
          "-=12"
        )
        .play(),
      design: new gsap.timeline({
        repeat: -1,
      })
        .set("#svg--design--1", {
          opacity: 1,
        })
        .to(
          "#svg--design--1",
          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=8"
        )
        .to(
          "#svg--design--1",
          { duration: 1, opacity: 1, ease: Power1.easeOut },
          "-=6"
        )
        .repeatDelay(7)
        .set("#svg--design--2", {
          opacity: 1,
        })
        .to(
          "#svg--design--2",
          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=8"
        )
        .to(
          "#svg--design--2",
          { duration: 1, opacity: 1, ease: Power1.easeOut, delay: 1 },
          "-=6"
        )
        .repeatDelay(6)
        .set("#svg--design--3", {
          opacity: 1,
        })
        .to(
          "#svg--design--3",
          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=8"
        )
        .to(
          "#svg--design--3",
          { duration: 1, opacity: 1, ease: Power1.easeOut, delay: 2 },
          "-=6"
        )
        .repeatDelay(5)
        .set("#svg--design--4", {
          opacity: 1,
        })
        .to(
          "#svg--design--4",
          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=8"
        )
        .to(
          "#svg--design--4",
          { duration: 1, opacity: 1, ease: Power1.easeOut, delay: 3 },
          "-=6"
        )
        .repeatDelay(4)
        .set("#svg--design--5", {
          opacity: 1,
        })
        .to(
          "#svg--design--5",

          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=8"
        )
        .to(
          "#svg--design--5",

          { duration: 1, opacity: 1, ease: Power1.easeOut, delay: 4 },
          "-=6"
        )
        .repeatDelay(3)
        .set("#svg--design--6", {
          opacity: 1,
        })
        .to(
          "#svg--design--6",

          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=8"
        )
        .to(
          "#svg--design--6",

          { duration: 1, opacity: 1, ease: Power1.easeOut, delay: 5 },
          "-=6"
        )
        .repeatDelay(2),
      system: new gsap.timeline({
        repeat: -1,
      })
        .set("#svg--system--1", {
          opacity: 1,
        })
        .to(
          "#svg--system--1",

          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=7"
        )
        .to(
          "#svg--system--1",

          { duration: 1, opacity: 1, ease: Power1.easeOut },
          "-=5"
        )
        .set("#svg--system--2", {
          opacity: 1,
        })
        .to(
          "#svg--system--2",

          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=7"
        )
        .to(
          "#svg--system--2",

          { duration: 1, opacity: 1, ease: Power1.easeOut, delay: 1 },
          "-=5"
        )
        .set("#svg--system--3", {
          opacity: 1,
        })
        .to(
          "#svg--system--3",

          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=7"
        )
        .to(
          "#svg--system--3",

          { duration: 1, opacity: 1, ease: Power1.easeOut, delay: 2 },
          "-=5"
        )
        .set("#svg--system--4", {
          opacity: 1,
        })
        .to(
          "#svg--system--4",

          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=7"
        )
        .to(
          "#svg--system--4",

          { duration: 1, opacity: 1, ease: Power1.easeOut, delay: 3 },
          "-=5"
        )
        .set("#svg--system--5", {
          opacity: 1,
        })
        .to(
          "#svg--system--5",

          { duration: 1, opacity: 0, ease: Power1.easeOut, delay: 1 },
          "-=7"
        )
        .to(
          "#svg--system--5",

          { duration: 1, opacity: 1, ease: Power1.easeOut, delay: 4 },
          "-=5"
        )
        .repeatDelay(2),
      more: new gsap.timeline({
        repeat: -1,
      })
        .set("#svg--more--1", {
          transformOrigin: "center",
        })
        .to("#svg--more--1", {
          duration: 1,
          startAt: {
            rotationZ: 0,
          },
          rotationZ: 180,
          ease: Power1.easeOut,
        })
        .to("#svg--more--1", {
          duration: 1,
          startAt: {
            rotationZ: 180,
          },
          rotationZ: 360,
          ease: Power1.easeOut,
          delay: 1,
        })
        .repeatDelay(1),
    },
    bounce: {
      web: new gsap.timeline({
        repeat: -1,
      })
        .to(".svg--web", {
          duration: 0.5,
          top: "-30px",
          ease: Power1.easeOut,
        })
        .to(".svg--web", {
          duration: 1.5,
          top: "0px",
          ease: Bounce.easeOut,
        })
        .pause(),
      design: new gsap.timeline({
        repeat: -1,
      })
        .to(".svg--design", {
          duration: 0.5,
          top: "-30px",
          ease: Power1.easeOut,
        })
        .to(".svg--design", {
          duration: 1.5,
          top: "0px",
          ease: Bounce.easeOut,
        })
        .pause(),
      system: new gsap.timeline({
        repeat: -1,
      })
        .to(".svg--system", {
          duration: 0.5,
          top: "-30px",
          ease: Power1.easeOut,
        })
        .to(".svg--system", {
          duration: 1.5,
          top: "0px",
          ease: Bounce.easeOut,
        })
        .pause(),
      more: new gsap.timeline({
        repeat: -1,
      })
        .to(".svg--more", {
          duration: 0.5,
          top: "-30px",
          ease: Power1.easeOut,
        })
        .to(".svg--more", {
          duration: 1.5,
          top: "0px",
          ease: Bounce.easeOut,
        })
        .pause(),
    },
  };

  const getCurrentSection = () => {
    return location.hash
      ? location.hash.replace(/#([^\/0-9]+)(\/.*)?$/, "$1")
      : "home";
  };

  const setPulse = (sectionName) => {
    $("nav a").removeClass(
      "animate__animated animate__pulse animate__infinite"
    );
    if (sectionName !== "home") {
      $("nav")
        .find("a")
        .eq($.inArray(sectionName, anchors))
        .addClass("animate__animated animate__pulse animate__infinite");
    }
  };

  const setStoreName = (storeName) => {
    $("nav li.home").attr("class", "home").addClass(`store-name--${storeName}`);
  };

  const createHeader = () => {
    if ($("header")[0]) $("header").remove();
    let $header = $("<header>");
    $header.append(
      $("<a>", {
        href: "javascript:void(0);",
        id: "panel-btn",
      }).append(
        $("<span>", {
          id: "panel-btn-icon",
        })
      )
    );

    $header.append(
      $("<nav>").append(() => {
        let $ul = $("<ul>");
        $.each(node, (i, child) => {
          let sectionName = child.anchor.replace(/^(#|\/)?(.+?)\/?$/, "$2");
          let $li = $("<li>")
            .addClass(() => {
              if (sectionName !== "home") {
                return `${sectionName} toggle`;
              } else {
                return sectionName;
              }
            })
            .append(
              $("<a>", {
                id: child.anchor.replace(/#([^\/0-9]+)(\/.*)?$/, "$1"),
                href: child.anchor,
              })
                .text(child.title)
                .addClass("sweep")
            );
          $ul.append($li);
        });
        return $ul;
      })
    );
    return $header;
  };

  const setPulldownMenu = () => {
    // プルダウンメニュー（メイン）
    $(document).on("click", "#panel-btn", () => {
      $("nav li.toggle").slideToggle(200);
      $("#panel-btn-icon").toggleClass("close");
    });
  };

  const setMouseoverMenu = () => {
    $(document).on("mouseenter", "nav a", (e) => {
      $(e.currentTarget).addClass("animate__animated animate__pulse");
    });
    $(document).on("mouseleave", "nav a", (e) => {
      if (!$(e.currentTarget).hasClass("animate__infinite")) {
        $(e.currentTarget).removeClass("animate__animated animate__pulse");
      }
    });
  };

  const setLineTitle = () => {
    setTimeout(() => {
      if ($(window).outerWidth() >= 768) {
        $(".horizontal-line").css(
          "top",
          100 * ((1080 - 808) / 2 / 1080 - 0.04 / 2) + "%"
        );
        $(".line__title").css("top", "50%");
      } else {
        $(".horizontal-line").css(
          "top",
          (((-100 * 1) / 2) * $("section.concept .left").outerHeight()) /
            $("section.concept .right").outerHeight() +
            "%"
        );
        $(".line__title").css(
          "top",
          (((100 * (1080 - 48 - 430)) / 480) *
            (1 / 2 - 20 / 430) *
            $("section.concept .left").outerHeight()) /
            $("section.concept .right").outerHeight() +
            "%"
        );
      }
    }, 500);
  };

  $(document).on("click touchstart", "nav a", (e) => {
    let section = parseInt($(e.currentTarget).parent().index()) + 1;
    if (section !== 6) {
      e.preventDefault();
      fullpage_api.moveTo(section);
    }
  });

  $(document).on("click touchstart", ".icon__wrapper a", (e) => {
    let section = parseInt($(e.currentTarget).parents("section").index()) + 1;
    let slide = parseInt($(e.currentTarget).parents(".icon").index()) + 1;
    e.preventDefault();
    fullpage_api.moveTo(section, slide);
  });

  $(document).on("click", "nav a", () => {
    if ($(window).outerWidth() < 768) {
      $("nav li.toggle").slideUp(200);
      $("#panel-btn-icon").removeClass("close");
    }
  });

  $(window).on("hashchange", () => {
    if ($(window).outerWidth() >= 768) {
      if (
        $("body").hasClass("fp-viewing-home") &&
        (!$("section.home").hasClass("fp-completely") || isFirst)
      ) {
        let flagIsFirst = isFirst;
        if (!flagIsFirst) {
          gsap.set("header", {
            y: 0,
            opacity: 1,
          });
          gsap.to("header", { duration: 1, y: -48, opacity: 0 });
        }

        setTimeout(
          () => {
            $("section.home").append($header);
            setStoreName("b");
            if (!$("#panel-btn").hasClass("hide"))
              $("#panel-btn").addClass("hide");
            gsap.set("header", {
              y: 48,
              opacity: 0,
            });
            gsap.to("header", {
              duration: 1,
              y: 0,
              opacity: 1,
            });

            if (!flagIsFirst) {
              gsap.set("footer", {
                y: 0,
                opacity: 1,
              });
              gsap.to("footer", {
                duration: 1,
                y: 32,
                opacity: 0,
              });
            }
          },
          flagIsFirst ? 0 : 1600
        );
      } else if (
        !$("body").hasClass("fp-viewing-home") &&
        ($("section.home").hasClass("fp-completely") || isFirst)
      ) {
        let flagIsFirst = isFirst;
        if (!flagIsFirst) {
          gsap.set("header", {
            opacity: 1,
          });
          gsap.to("header", { duration: 1, opacity: 0 });
        }

        setTimeout(
          () => {
            $("#container").append($header);
            setStoreName("a");
            if (!$("#panel-btn").hasClass("hide"))
              $("#panel-btn").addClass("hide");
            setPulse(getCurrentSection());
            gsap.set("header", {
              y: -48,
              opacity: 0,
            });
            gsap.to("header", {
              duration: 1,
              y: 0,
              opacity: 1,
            });
            gsap.set("footer", {
              y: 32,
              opacity: 0,
            });
            gsap.to("footer", { duration: 1, y: 0, opacity: 1 });
          },
          flagIsFirst ? 0 : 1600
        );
      }
    } else {
      $("#container").append($header);
      setStoreName("a");
      if (isFirst) {
        setPulse(getCurrentSection());
        gsap.set("header", {
          y: -48,
          opacity: 0,
        });
        gsap.to("header", { duration: 1, y: 0, opacity: 1 });
      }
      if (
        $("body").hasClass("fp-viewing-home") &&
        !$("section.home").hasClass("fp-completely")
      ) {
        gsap.set("footer", {
          y: 0,
        });
        gsap.to("footer", {
          duration: 1,
          y: 32,
          opacity: 0,
          delay: 1.6,
        });
      } else if (
        !$("body").hasClass("fp-viewing-home") &&
        ($("section.home").hasClass("fp-completely") || isFirst)
      ) {
        gsap.set("footer", {
          y: 32,
        });
        gsap.to("footer", {
          duration: 1,
          y: 0,
          opacity: 1,
          delay: 1.6,
        });
      }
    }
    setPulse(getCurrentSection());
    if (isFirst) isFirst = false;
  });

  $(window).on("load resize", () => {
    let $width = $(window).outerWidth();
    let $height = $(window).outerHeight();
    if ($width / $height > 16 / 9) {
      $("#main-image").css("backgroundSize", $width + "px auto");
    } else {
      $("#main-image").css("backgroundSize", "auto " + $height + "px");
    }
    if ($width >= 768) {
      if (!$("#panel-btn").hasClass("hide")) {
        $("#panel-btn").addClass("hide");
        if (!$("#panel-btn-icon").hasClass("close")) {
          $("#panel-btn-icon").addClass("close");
        }
        $("nav li.toggle").slideDown(200);
      }
      if ($("body").hasClass("fp-viewing-home")) {
        $("#container header").remove();
        $("section.home").append($header);
        setStoreName("b");
      } else {
        setStoreName("a");
      }
    } else {
      if ($("#panel-btn").hasClass("hide")) {
        $("#panel-btn").removeClass("hide");
        if ($("#panel-btn-icon").hasClass("close")) {
          $("#panel-btn-icon").removeClass("close");
        }
        $("nav li.toggle").slideUp(200);
      }
      if ($("body").hasClass("fp-viewing-home")) {
        $("section.home header").remove();
        $("#container").append($header);
        setStoreName("a");
      }
    }
  });

  $(".col").on("inview", (e, isInView) => {
    const $target = $(e.currentTarget);
    if (isInView && $target.find(".image__main").css("opacity") === "0") {
      let horizontalMovingX,
        horizontalMovingY,
        verticalMovingX,
        verticalMovingY,
        axisX,
        axisY;
      if ($(window).outerWidth() >= 768) {
        horizontalMovingX =
          parseInt($(window).outerWidth()) -
          parseInt($target.find(".horizontal-line").css("left"));
        horizontalMovingY = 0;
        verticalMovingX = 0;
        verticalMovingY =
          parseInt($(window).outerHeight()) -
          parseInt($target.find(".vertical-line").css("top"));
        axisX = -360;
        axisY = 0;
      } else {
        horizontalMovingX = 0;
        horizontalMovingY =
          parseInt($(window).outerHeight()) -
          parseInt($target.find(".horizontal-line").css("top"));
        verticalMovingX =
          parseInt($(window).outerWidth()) -
          parseInt($target.find(".vertical-line").css("left"));
        verticalMovingY = 0;
        axisX = 0;
        axisY = -360;
      }

      new gsap.timeline()
        .set($target.find(".image__above"), {
          x: -5,
          y: 10,
        })
        .set($target.find(".image__below"), {
          x: -5,
          y: 10,
        })
        .set($target.find(".image__main"), {
          x: -5,
          y: 10,
        })
        .set($target.find(".horizontal-line"), {
          x: horizontalMovingX,
          y: horizontalMovingY,
        })
        .set($target.find(".vertical-line"), {
          x: verticalMovingX,
          y: verticalMovingY,
        })
        .set($target.find(".text"), {
          x: -5,
          y: 10,
        })
        .to($target.find(".image__above"), {
          duration: 0.5,
          opacity: 0.6,
          x: 0,
          y: 0,
          ease: Power1.easeOut,
          delay: 1.6,
        })
        .to($target.find(".image__below"), {
          duration: 0.5,
          opacity: 0.6,
          x: 0,
          y: 0,
          ease: Power1.easeOut,
        })
        .to($target.find(".image__main"), {
          duration: 0.5,
          opacity: 1,
          x: 0,
          y: 0,
          ease: Power1.easeOut,
        })
        .to($target.find(".horizontal-line"), {
          duration: 0.5,
          opacity: 1,
          x: 0,
          y: 0,
          ease: Power1.easeOut,
          onStart: setLineTitle,
        })
        .to($target.find(".vertical-line"), {
          duration: 0.5,
          opacity: 1,
          x: 0,
          y: 0,
          ease: Power1.easeOut,
        })
        .to($target.find(".text"), {
          duration: 1,
          opacity: 1,
          x: 0,
          y: 0,
          ease: Power1.easeOut,
          delay: 0.5,
          onComplete: () => {
            if ($target.find(".icon__wrapper").length) {
              new gsap.timeline()
                .set($target.find(".icon.web"), {
                  x: -5,
                  y: 10,
                })
                .set($target.find(".icon.design"), {
                  x: -5,
                  y: 10,
                })
                .set($target.find(".icon.system"), {
                  x: -5,
                  y: 10,
                })
                .set($target.find(".icon.more"), {
                  x: -5,
                  y: 10,
                })
                .set($target.find(".line__title"), {
                  rotationX: axisX,
                  rotationY: axisY,
                  transformOrigin: "center",
                })
                .to($target.find(".icon.web"), {
                  duration: 0.5,
                  opacity: 1,
                  x: 0,
                  y: 0,
                  ease: Power1.easeOut,
                })
                .to($target.find(".icon.design"), {
                  duration: 0.5,
                  opacity: 1,
                  x: 0,
                  y: 0,
                  ease: Power1.easeOut,
                })
                .to($target.find(".icon.system"), {
                  duration: 0.5,
                  opacity: 1,
                  x: 0,
                  y: 0,
                  ease: Power1.easeOut,
                })
                .to($target.find(".icon.more"), {
                  duration: 0.5,
                  opacity: 1,
                  x: 0,
                  y: 0,
                  ease: Power1.easeOut,
                })
                .to($target.find(".line__title"), {
                  duration: 1,
                  rotationX: 0,
                  rotationY: 0,
                  ease: Power1.easeOut,
                  delay: 1,
                });
            } else {
              new gsap.timeline()
                .set($target.find(".line__title"), {
                  rotationX: axisX,
                  rotationY: axisY,
                  transformOrigin: "center",
                })
                .to($target.find(".line__title"), {
                  duration: 1,
                  rotationX: 0,
                  rotationY: 0,
                  ease: Power1.easeOut,
                  delay: 1,
                });
            }
          },
        });
    }
  });

  $("section.about, section.staff").on("inview", (e, isInView) => {
    const $target = $(e.currentTarget);
    if (isInView && $target.find(".text").css("opacity") === "0") {
      new gsap.timeline()
        .set($target.find(".text"), {
          x: -5,
          y: 10,
          opacity: 0,
        })
        .to($target.find(".text"), {
          duration: 1,
          x: 0,
          y: 0,
          opacity: 1,
          ease: Power1.easeOut,
          delay: 2,
        });
    }
  });

  $(".icon__wrapper a").on({
    mouseenter: (e) => {
      const key = $(e.currentTarget)
        .parents(".icon")
        .children(".icon__title")
        .text()
        .toLowerCase();
      tl.normal[key].pause(0);
      tl.bounce[key].repeat(-1).restart();
    },
    mouseleave: (e) => {
      const key = $(e.currentTarget)
        .parents(".icon")
        .children(".icon__title")
        .text()
        .toLowerCase();
      tl.bounce[key].repeat(0);
      tl.normal[key].restart();
    },
  });

  $("section.home #main-title").html(mainTitle());

  new fullpage("#js-fullpage", {
    licenseKey: "ZPL7J-2Q31I-6ERJ9-802OK-INJRP", // ver.4
    // licenseKey: "145CE221-B31E46F4-87D55FB1-11E3F932", // ver.3
    anchors: anchors,
    scrollingSpeed: 1600,
    controlArrows: false,
    loopBottom: false,
    loopTop: false,
    loopHorizontal: true,
    navigation: true,
    navigationPosition: "right",
    slidesNavigation: true,
    slidesNavPosition: "bottom",
    autoScrolling: true,
    menu: "nav",
    easing: "easeOut",
  });

  $(".slider-pro").sliderPro({
    width: "100%",
    height: "100%",
    thumbnailWidth: 240,
    thumbnailHeight: 135,
    thumbnailPointer: true,
    thumbnailTouchSwipe: false,
    fade: true,
    fadeDuration: 2000,
    keyboard: false,
    touchSwipe: false,
  });

  if ($("body").hasClass("fp-viewing-home")) {
    new gsap.timeline()
      .set("#main-title .st0", {
        opacity: 0,
      })
      .set("#main-title .st1", {
        fill: "#666",
        opacity: 0,
      })
      .set("footer", {
        y: "32px",
      })
      .to("#main-title .st1", {
        duration: 2,
        opacity: 1,
        ease: Power1.easeOut,
        delay: 1,
      })
      .to("#main-title .st1", {
        duration: 2,
        opacity: 0,
        ease: Power1.easeOut,
        delay: 1,
      })
      .set("#main-title .st1", {
        fill: "#fff",
      })
      .to("#main-title .st1", {
        duration: 2,
        opacity: 1,
        ease: Power1.easeOut,
      })
      .to(
        "#main-image",

        { duration: 2, opacity: 1, ease: Power1.easeOut },
        "-=4"
      )
      .to(
        "#main-title .st0",

        { duration: 2, opacity: 1, ease: Power1.easeOut },
        "-=2"
      )
      .to(
        "#logo",

        {
          duration: 2,
          opacity: 1,
          ease: Power1.easeOut,
          onStart: () => {
            $(window).trigger("hashchange");
          },
        },
        "-=2"
      )
      .play();
  } else {
    new gsap.timeline()
      .set("#main-title .st1", {
        opacity: 1,
        fill: "#fff",
      })
      .set("#main-title .st0", {
        opacity: 1,
      })
      .set("#main-image", {
        opacity: 1,
      })
      .set("#logo", {
        opacity: 1,
        onComplete: () => {
          $(window).trigger("hashchange");
        },
      })
      .play();
  }

  let $header = createHeader();

  setLineTitle();
  setMouseoverMenu();
  setPulldownMenu();
  setPulse(getCurrentSection());

  $("body").css("visibility", "visible");
});
